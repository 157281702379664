import { useRoutes } from 'react-router-dom'

import loadable from '@loadable/component'

const Login = loadable(() => import('../pages/Auth/Login'))
const Role = loadable(() => import('../pages/System/Role'))
const User = loadable(() => import('../pages/System/User'))
const MainLayout = loadable(() => import('../layout/MainLayout'))
const News = loadable(() => import('../pages/News'))
const Question = loadable(() => import('../pages/Question'))
const Correction = loadable(() => import('../pages/Question/Correction'))
const Employee = loadable(() => import('../pages/Employee'))
const BreakRule = loadable(() => import('../pages/Employee/BreakRule'))
const Exam = loadable(() => import('../pages/Exam'))
const ExamArchive = loadable(() => import('../pages/Exam/Archive'))
const Daily = loadable(() => import('../pages/Answer/Daily'))
const Answer = loadable(() => import('../pages/Answer'))
const Weekly = loadable(() => import('../pages/Answer/Weekly'))
const Special = loadable(() => import('../pages/Answer/Special'))
const Challenge = loadable(() => import('../pages/Answer/Challenge'))
const Video = loadable(() => import('../pages/Answer/Video'))
const Course = loadable(() => import('../pages/Course'))
const Train = loadable(() => import('../pages/Train'))
const Product = loadable(() => import('../pages/Integral/Product'))
const ProductOrder = loadable(() => import('../pages/Integral/ProductOrder'))
const Notice = loadable(() => import('../pages/Notice'))
const Settings = loadable(() => import('../pages/Settings'))
const Navigator = loadable(() => import('../pages/Settings/Navigator'))
const Banner = loadable(() => import('../pages/Settings/Banner'))
const Cert = loadable(() => import('../pages/Employee/Cert'))
const Archive = loadable(() => import('../pages/Employee/Archive'))
const Rules = loadable(() => import('../pages/Integral/Rules'))
const Integral = loadable(() => import('../pages/Integral'))
const File = loadable(() => import('../pages/File'))
const SuperAdminLogin = loadable(
  () => import('../pages/SuperAdmin/Login/SuperAdminLogin')
)
const SuperAdminList = loadable(
  () => import('../pages/SuperAdmin/System/Admin/SuperAdminList')
)
const SuperAdminLayout = loadable(() => import('../layout/SuperAdminLayout'))
const SuperAdminTenant = loadable(
  () => import('../pages/SuperAdmin/Tenant/SuperAdminTenant')
)
const SuperAdminMenu = loadable(
  () => import('../pages/SuperAdmin/System/Menu/SuperAdminMenu')
)
const SuperAdminDict = loadable(
  () => import('../pages/SuperAdmin/System/Dict/SuperAdminDict')
)
const SuperAdminModule = loadable(
  () => import('../pages/SuperAdmin/System/Module/SuperAdminModule')
)
const SuperAdminTenantRole = loadable(
  () => import('../pages/SuperAdmin/TenantRole/SuperAdminTenantRole')
)
const SuperAdminUser = loadable(
  () => import('../pages/SuperAdmin/User/SuperAdminUser')
)
const SuperAdminUserRole = loadable(
  () => import('../pages/SuperAdmin/UserRole/SuperAdminUserRole')
)
const Home = loadable(() => import('../pages/Home'))
const External = loadable(() => import('../pages/Train/External'))
const IntegralSettings = loadable(() => import('../pages/Integral/Settings'))
const SuperSystemNotice = loadable(
  () => import('../pages/SuperAdmin/System/Notice')
)

const Index = () => {
  const routes = useRoutes([
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/super/login',
      element: <SuperAdminLogin />,
    },
    {
      path: '/super',
      element: <SuperAdminLayout />,
      children: [
        {
          path: '/super',
          element: <SuperAdminList />,
        },
        {
          path: '/super/system',
          children: [
            {
              path: '/super/system/admin',
              element: <SuperAdminList />,
            },
            {
              path: '/super/system/menu',
              element: <SuperAdminMenu />,
            },
            {
              path: '/super/system/dict',
              element: <SuperAdminDict />,
            },
            {
              path: '/super/system/module',
              element: <SuperAdminModule />,
            },
            {
              path: '/super/system/notice',
              element: <SuperSystemNotice />,
            },
          ],
        },
        {
          path: '/super/tenant',
          children: [
            {
              path: '/super/tenant',
              element: <SuperAdminTenant />,
            },
            {
              path: '/super/tenant/role',
              element: <SuperAdminTenantRole />,
            },
          ],
        },
        {
          path: '/super/user',
          children: [
            {
              path: '/super/user',
              element: <SuperAdminUser />,
            },
            {
              path: '/super/user/role',
              element: <SuperAdminUserRole />,
            },
          ],
        },
      ],
    },

    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: <Home />,
        },
        {
          path: '/home',
          element: <Home />,
        },
        {
          path: '/system',
          children: [
            {
              path: '/system',
              element: <User />,
            },
            {
              path: '/system/user',
              element: <User />,
            },
            {
              path: '/system/role',
              element: <Role />,
            },
          ],
        },
        {
          path: '/news',
          element: <News />,
        },
        {
          path: '/answer',
          children: [
            {
              path: '/answer',
              element: <Answer />,
            },
            {
              path: '/answer/daily',
              element: <Daily />,
            },
            {
              path: '/answer/weekly',
              element: <Weekly />,
            },
            {
              path: '/answer/special',
              element: <Special />,
            },
            {
              path: '/answer/challenge',
              element: <Challenge />,
            },
            {
              path: '/answer/video',
              element: <Video />,
            },
          ],
        },
        {
          path: '/exam',
          children: [
            {
              path: '/exam',
              element: <Exam />,
            },
            {
              path: '/exam/archive',
              element: <ExamArchive />,
            },
          ],
        },
        {
          path: '/course',
          element: <Course />,
        },
        {
          path: '/question',
          children: [
            {
              path: '/question',
              element: <Question />,
            },
            {
              path: '/question/correction',
              element: <Correction />,
            },
          ],
        },

        {
          path: '/employee',
          children: [
            {
              path: '/employee',
              element: <Employee />,
            },
            {
              path: '/employee/break-rule',
              element: <BreakRule />,
            },
            {
              path: '/employee/cert',
              element: <Cert />,
            },
            {
              path: '/employee/archive',
              element: <Archive />,
            },
          ],
        },
        {
          path: '/train',
          children: [
            {
              path: '/train',
              element: <Train />,
            },
            {
              path: '/train/external',
              element: <External />,
            },
          ],
        },
        {
          path: '/integral',
          children: [
            {
              path: '/integral',
              element: <Integral />,
            },
            {
              path: '/integral/settings',
              element: <IntegralSettings />,
            },
            {
              path: '/integral/product',
              element: <Product />,
            },
            {
              path: '/integral/product-order',
              element: <ProductOrder />,
            },
            {
              path: '/integral/rules',
              element: <Rules />,
            },
          ],
        },
        {
          path: '/notice',
          children: [
            {
              path: '/notice',
              element: <Notice />,
            },
          ],
        },
        {
          path: '/settings',
          children: [
            {
              path: '/settings',
              element: <Settings />,
            },
            {
              path: '/settings/nav',
              element: <Navigator />,
            },
            {
              path: '/settings/banner',
              element: <Banner />,
            },
          ],
        },
        {
          path: '/file',
          children: [
            {
              path: '/file',
              element: <File />,
            },
          ],
        },
      ],
    },
  ])
  return routes
}

export default Index
